<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card style="max-width: 100%; width: 100%" class="mb-2">
        <b-tabs content-class="mt-3" pills @activate-tab="tabChanged">
          <b-tab :title="$t('COMMON.MAIN')" active>
            <b-card-body>
              <b-form-group
                id="field-name"
                label-cols-lg="2"
                :label="$t('COMMON.NAME')"
                label-for="field-name-input"
              >
                <b-form-input
                  id="field-name-input"
                  v-model="dto.name"
                  trim
                  :state="validateDtoState('name')"
                  aria-describedby="name-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback
                  id="name-live-feedback"
                  :state="validateDtoState('name')"
                >
                  {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="field-location-manual"
                label-cols-lg="2"
                :label="$t('DEVICE.LOCATION_MANUAL')"
                label-for="field-location-manual-input"
              >
                <b-form-checkbox
                  id="checkbox-location-manual-input"
                  v-model="dto.locationManual"
                  name="checkbox-location-manual"
                  class="pt-2"
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                id="field-amend-timezone"
                label-cols-lg="2"
                :label="$t('DEVICE.AMEND_TIMEZONE')"
                label-for="field-amend-timezone-input"
              >
                <b-form-checkbox
                  id="checkbox-amend-timezone-input"
                  v-model="dto.amendTimezone"
                  name="checkbox-amend-timezone"
                  class="pt-2"
                  size="lg"
                >
                </b-form-checkbox>
              </b-form-group>

              <b-form-group
                :label="$t('DEVICE.TRUST_LEVEL')"
                label-for="trust-type"
                label-cols-lg="2"
              >
                <b-form-select
                  v-model="dto.trustLevelId"
                  :state="validateDtoState('trustLevelId')"
                  id="trust-type"
                  :options="trustLevels"
                  aria-describedby="trust-live-feedback"
                ></b-form-select>
                <b-form-invalid-feedback
                  id="trust-live-feedback"
                  :state="validateDtoState('trustLevelId')"
                >
                  {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                :label="$t('DEVICE.INTEGRATION')"
                label-for="integration"
                label-cols-lg="2"
              >
                <b-form-select
                  v-model="dto.integrationId"
                  id="integration"
                  :state="validateDtoState('integrationId')"
                  :options="integrations"
                  aria-describedby="integration-live-feedback"
                ></b-form-select>
                <b-form-invalid-feedback
                  id="integration-live-feedback"
                  :state="validateDtoState('integrationId')"
                >
                  {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-card-body>
          </b-tab>
          <b-tab :title="$t('DEVICE.PARAMETER_MAPPING')">
            <b-table
              id="table-parameters"
              striped
              bordered
              :items="dto.parameterMappings"
              :fields="parameterMappingFields"
              selectable
              select-mode="single"
              @row-selected="onParameterRowSelected"
            >
            </b-table>
          </b-tab>

          <b-tab :title="$t('DEVICE.COMMANDS')">
            <b-table
              id="table-commands"
              striped
              bordered
              :items="dto.commands"
              :fields="commandFields"
              selectable
              select-mode="single"
              @row-selected="onCommandRowSelected"
            >
            </b-table>
          </b-tab>
        </b-tabs>

        <div style="float: right">
          <b-button variant="success" v-on:click="onSaveClicked">{{
            $t("COMMON.SAVE")
          }}</b-button>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-create-edit-command"
      :title="
        addItem ? this.$t('DEVICE.ADD_COMMAND') : this.$t('DEVICE.EDIT_COMMAND')
      "
      size="xl"
      ok-only
      @ok="commandModalOkPressed"
      :ok-title="$t('COMMON.SAVE')"
      scrollable
    >
      <b-form-group
        id="field-command-name"
        label-cols-lg="2"
        :label="$t('COMMON.NAME')"
        :state="validateCommandState('name')"
        label-for="field-command-name-input"
      >
        <b-form-input
          id="field-command-name-input"
          v-model="command.name"
          trim
          aria-describedby="command-name-live-feedback"
        >
        </b-form-input>
        <b-form-invalid-feedback id="command-name-live-feedback">
          {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="field-command-description"
        label-cols-lg="2"
        :label="$t('COMMON.DESCRIPTION')"
        label-for="field-command-description-input"
      >
        <b-form-input
          id="field-command-description-input"
          v-model="command.description"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="field-command-value"
        label-cols-lg="2"
        :label="$t('DEVICE.VALUE')"
        label-for="field-command-value-input"
      >
        <b-form-input
          id="field-command-value-input"
          v-model="command.value"
          trim
        ></b-form-input>
      </b-form-group>

      <b-table
        id="table-command-properties"
        striped
        bordered
        :items="command.commandProperties"
        :fields="commandPropertyFields"
        selectable
        select-mode="single"
        @row-selected="onCommandPropertyRowSelected"
      >
        <!-- CODE -->
        <template v-slot:cell(code)="data">
          <b-form-input v-model="data.item.code" trim size="sm" />
        </template>

        <!-- NAME -->
        <template v-slot:cell(name)="data">
          <b-form-input v-model="data.item.name" trim size="sm"></b-form-input>
        </template>

        <!-- DESCRIPTION -->
        <template v-slot:cell(description)="data">
          <b-form-input
            v-model="data.item.description"
            trim
            size="sm"
          ></b-form-input>
        </template>

        <!-- DESCRIPTION -->
        <template v-slot:cell(defaultValue)="data">
          <b-form-input
            v-model="data.item.defaultValue"
            trim
            size="sm"
          ></b-form-input>
        </template>
      </b-table>

      <b-button
        variant="secondary"
        size="sm"
        @click="commandPropertyAddClicked"
        >{{ $t("DEVICE.ADD_PROPERTY") }}</b-button
      >
      <b-button
        variant="secondary"
        size="sm"
        @click="commandPropertyDeleteClicked"
        :disabled="selectedCommandProperty === null"
        >{{ $t("ACTIONS.DELETE") }}</b-button
      >
    </b-modal>

    <b-modal
      id="modal-create-edit-parameter"
      :title="
        addItem
          ? $t('DEVICE.ADD_PARAMETER_MAPPING')
          : $t('DEVICE.EDIT_PARAMETER_MAPPING')
      "
      size="lg"
      ok-only
      static
      @ok="parameterModalOkPressed"
      :ok-title="$t('COMMON.SAVE')"
    >
      <b-form-group
        id="field-code"
        label-cols-lg="2"
        :label="$t('DEVICE.CODE')"
        label-for="field-code-input"
      >
        <b-form-input
          id="field-code-input"
          v-model="parameterMapping.code"
          trim
          :state="validateState('code')"
          aria-describedby="input-1-live-feedback"
        >
        </b-form-input>
        <b-form-invalid-feedback
          id="input-1-live-feedback"
          :state="validateState('code')"
        >
          {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="field-translate-expression"
        label-cols-lg="2"
        :label="$t('DEVICE.TRANSLATE_EXPRESSION')"
        label-for="field-translate-expression-input"
      >
        <b-form-textarea
          id="field-translate-expression-input"
          v-model="parameterMapping.translateExpression"
          trim
          aria-describedby="input-2-live-feedback"
        >
        </b-form-textarea>
        <b-form-invalid-feedback id="input-2-live-feedback">
          {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        :label="$t('DEVICE.PARAMETER')"
        label-for="filter1"
        label-cols-lg="2"
      >
        <CompactSuggestion
          ref="parameterSuggestionRef"
          v-model="parameterMapping.parameterId"
          @hit="(val) => (parameterMapping.parameterName = val.name)"
          suggestionProcessor="parameterSuggestionProcessor"
        ></CompactSuggestion>

        <b-form-invalid-feedback
          id="parameter-id-live-feedback"
          :state="validateState('parameterId')"
        >
          {{ $t("COMMON.THIS_FIELD_IS_REQUIRED") }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="field-min-value"
        label-cols-lg="2"
        :label="$t('COMMON.MIN_VALUE')"
        label-for="field-min-value-input"
      >
        <b-form-input
          id="field-min-value-input"
          v-model="parameterMapping.minValue"
          trim
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="field-max-value"
        label-cols-lg="2"
        :label="$t('COMMON.MAX_VALUE')"
        label-for="field-max-value-input"
      >
        <b-form-input
          id="field-max-value-input"
          v-model="parameterMapping.maxValue"
          trim
        ></b-form-input>
      </b-form-group>
    </b-modal>

    <b-modal
      id="modal-delete-parameter-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="deleteParameterOkPressed"
    >
      <p class="my-4" v-if="selectedParameter">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedParameter.code,
          })
        }}
      </p>
    </b-modal>

    <b-modal
      id="modal-delete-command-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="deleteCommandOkPressed"
    >
      <p class="my-4" v-if="selectedCommand">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedCommand.name,
          })
        }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import CompactSuggestion from "../component/CompactSuggestion.vue";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "device-type",
  components: { CompactSuggestion },
  data() {
    return {
      save: {
        resource: "/api/device-type/save",
        requestType: "POST",
        requestParams: {},
      },
      getTrustLevels: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.TrustLevel",
        requestType: "GET",
        requestParams: {},
      },
      getIntegrations: {
        resource:
          "/platform/api/dictionary/com.gracelogic.nlg.core.model.Integration",
        requestType: "GET",
        requestParams: {},
      },

      TAB_IDENTIFIERS: 1,

      dto: {
        id: null,
        integrationId: null,
        trustLevelId: null,
        locationManual: false,
        amendTimezone: false,
        name: null,

        parameterMappings: [],
        parameterMappingsToDelete: [],
        commands: [],
        commandsToDelete: [],
      },

      parameterMappingFields: [
        { key: "parameterName", label: this.$t("DEVICE.PARAMETER") },
        { key: "code", label: this.$t("DEVICE.CODE") },
        {
          key: "translateExpression",
          label: this.$t("DEVICE.TRANSLATE_EXPRESSION"),
        },
        { key: "minValue", label: this.$t("COMMON.MIN_VALUE") },
        { key: "maxValue", label: this.$t("COMMON.MAX_VALUE") },
      ],

      commandFields: [
        { key: "name", label: this.$t("COMMON.NAME") },
        { key: "description", label: this.$t("COMMON.DESCRIPTION") },
        { key: "value", label: this.$t("DEVICE.VALUE") },
      ],

      commandPropertyFields: [
        { key: "#", formatter: () => "" },
        { key: "code", label: this.$t("DEVICE.CODE") },
        { key: "name", label: this.$t("COMMON.NAME") },
        { key: "description", label: this.$t("COMMON.DESCRIPTION") },
        { key: "defaultValue", label: this.$t("DEVICE.DEFAULT_VALUE") },
      ],

      parameterMapping: this.makeEmptyParameter(), // текущий редактируемый параметр
      command: this.makeEmptyCommand(), // текущая редактируемая команда

      addItem: false, // определяет, добавлять или изменять текущий parameterMapping или command

      trustLevels: [],
      integrations: [],
      parameters: [],

      selectedParameter: null, // параметр, выбранный в таблице
      selectedCommand: null, // команда, выбранная в таблице
      selectedCommandProperty: null,

      showOverlay: true,
      confirmPassword: null,

      createNewStr: this.$t("DEVICE.CREATE_NEW_DEVICE_TYPE"),
      editStr: this.$t("DEVICE.EDIT_DEVICE_TYPE"),
    };
  },

  validations: {
    command: {
      name: { required },
      commandProperties: {
        $each: {
          name: { required },
          code: { required },
          defaultValue: { required },
        },
      },
    },
    dto: {
      name: { required },
      trustLevelId: { required },
      integrationId: { required },
      commands: {
        $each: {
          name: { required },
          commandProperties: {
            $each: {
              name: { required },
              code: { required },
              defaultValue: { required },
            },
          },
        },
      },
    },

    parameterMapping: {
      code: {
        required,
      },
      parameterId: {
        required,
      },
    },
  },

  mounted() {
    let title = !this.$route.params.id ? this.createNewStr : this.editStr;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.DEVICE_TYPES"), route: "/device-types" },
      { title: title },
    ]);
    this.$store.dispatch(SET_ACTIONS, null);
  },

  created: async function () {
    this.$store
      .dispatch(API_REQUEST, this.getTrustLevels)
      .then((response) => {
        for (let i = 0; i < response.length; i++)
          this.trustLevels.push({
            value: response[i].id,
            text: response[i].nameLocalized,
          });
      })
      .catch(this.onError);

    let result1 = await this.$store
      .dispatch(API_REQUEST, this.getIntegrations)
      .catch(this.onError);

    if (result1 === undefined) {
      return;
    }

    for (let i = 0; i < result1.length; i++)
      this.integrations.push({ value: result1[i].id, text: result1[i].name });

    if (this.$route.params.id != null) {
      await this.load();
    }

    this.showOverlay = false;
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.parameterMapping[name];
      return $dirty ? !$error : null;
    },

    validateDtoState(name) {
      const { $dirty, $error } = this.$v.dto[name];
      return $dirty ? !$error : null;
    },

    validateCommandState(name) {
      const { $dirty, $error } = this.$v.command[name];
      return $dirty ? !$error : null;
    },

    load: function () {
      return this.$store
        .dispatch(API_REQUEST, {
          resource: "/api/device-type/" + this.$route.params.id,
          requestType: "GET",
          requestParams: { enrich: true },
        })
        .then((response) => {
          this.dto = response;
        })
        .catch(this.onError);
    },

    onSaveClicked: async function () {
      this.$v.dto.$touch();
      if (this.$v.dto.$anyError) {
        return;
      }

      this.showOverlay = true;
      this.save.dto = this.dto;
      await this.$store
        .dispatch(API_REQUEST, this.save)
        .then((response) => {
          this.dto.id = response.id;

          this.$bvToast.toast(this.$t("COMMON.SAVED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("MENU.DEVICE_TYPES"), route: "/device-types" },
            { title: this.editStr },
          ]);
        })
        .catch(this.onError);

      this.showOverlay = false;
    },

    tabChanged: async function (newTabIndex) {
      let that = this;
      switch (newTabIndex) {
        case 1:
          {
            // parameter mapping
            this.$store.dispatch(SET_ACTIONS, [
              {
                label: this.$t("ACTIONS.ADD"),
                action: this.onCreateNewParameterAction,
                icon: "fas fa-plus",
              },
              {
                label: this.$t("ACTIONS.EDIT"),
                action: this.onEditParameterAction,
                disabled: function () {
                  return that.selectedParameter === null || that.showOverlay;
                },
                icon: "fas fa-edit",
              },
              {
                label: this.$t("ACTIONS.DELETE"),
                action: this.onDeleteParameterAction,
                disabled: function () {
                  return that.selectedParameter === null || that.showOverlay;
                },
                icon: "fas fa-trash-alt",
              },
            ]);
          }
          break;
        case 2:
          {
            this.$store.dispatch(SET_ACTIONS, [
              {
                label: this.$t("ACTIONS.ADD"),
                action: this.onCreateNewCommandAction,
                icon: "fas fa-plus",
              },
              {
                label: this.$t("ACTIONS.EDIT"),
                action: this.onEditCommandAction,
                disabled: function () {
                  return that.selectedCommand === null || that.showOverlay;
                },
                icon: "fas fa-edit",
              },
              {
                label: this.$t("ACTIONS.DELETE"),
                action: this.onDeleteCommandAction,
                disabled: function () {
                  return that.selectedCommand === null || that.showOverlay;
                },
                icon: "fas fa-trash-alt",
              },
            ]);
          }
          break;
        default:
          this.$store.dispatch(SET_ACTIONS, null);
          break;
      }
    },

    onCommandRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedCommand = items[0];
      } else {
        this.selectedCommand = null;
      }
    },

    onParameterRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedParameter = items[0];
      } else {
        this.selectedParameter = null;
      }
    },

    //
    //
    // PARAMETERS ->

    suggestedParameterSelected: function (item) {
      this.parameterMapping.parameterId = item.id;
    },

    onCreateNewParameterAction: function () {
      this.addItem = true;
      this.parameterMapping = this.makeEmptyParameter();
      this.$refs.parameterSuggestionRef.clearValue();
      this.$bvModal.show("modal-create-edit-parameter");
    },

    onEditParameterAction: function () {
      this.addItem = false;
      this.parameterMapping = this.copyParameter(this.selectedParameter);
      this.$refs.parameterSuggestionRef.setText(
        this.parameterMapping.parameterName
      );
      this.$bvModal.show("modal-create-edit-parameter");
    },

    parameterModalOkPressed: function (bvModalEvt) {
      this.$v.parameterMapping.$touch();
      if (this.$v.parameterMapping.$anyError) {
        bvModalEvt.preventDefault();
        return;
      }

      if (this.addItem) {
        if (this.dto.parameterMappings === null)
          this.dto.parameterMappings = [];
        this.dto.parameterMappings.push(this.parameterMapping);
      } else {
        // update changes in actual object
        for (const field in this.parameterMapping) {
          this.selectedParameter[field] = this.parameterMapping[field];
        }
      }
    },

    onDeleteParameterAction: function () {
      this.$bvModal.show("modal-delete-parameter-confirmation");
    },

    deleteParameterOkPressed: function () {
      for (let i = 0; i < this.dto.parameterMappings.length; i++) {
        const mapping = this.dto.parameterMappings[i];
        if (mapping === this.selectedParameter) {
          this.dto.parameterMappings.splice(i, 1);
          if (!this.dto.parameterMappingsToDelete)
            this.dto.parameterMappingsToDelete = [];
          if (mapping.id && mapping.id != null)
            this.dto.parameterMappingsToDelete.push(mapping.id);
          return;
        }
      }
    },
    // <-

    //
    //
    // COMMANDS ->
    onCreateNewCommandAction: function () {
      this.addItem = true;
      this.command = this.makeEmptyCommand();
      this.$bvModal.show("modal-create-edit-command");
    },

    onEditCommandAction: function () {
      this.addItem = false;
      this.command = this.copyCommand(this.selectedCommand);
      this.$bvModal.show("modal-create-edit-command");
    },

    commandModalOkPressed: function (bvModalEvt) {
      this.$v.command.$touch();
      if (this.$v.command.$anyError) {
        bvModalEvt.preventDefault();
        return;
      }

      this.selectedCommandProperty = null;

      if (this.addItem) {
        if (this.dto.commands === null) this.dto.commands = [];
        this.dto.commands.push(this.command);
      } else {
        // update changes in actual object
        for (const field in this.command) {
          this.selectedCommand[field] = this.command[field];
        }
      }
    },

    onDeleteCommandAction: function () {
      this.$bvModal.show("modal-delete-command-confirmation");
    },

    deleteCommandOkPressed: function () {
      for (let i = 0; i < this.dto.commands.length; i++) {
        const mapping = this.dto.commands[i];
        if (mapping === this.selectedCommand) {
          this.dto.commands.splice(i, 1);
          if (!this.dto.commandsToDelete) this.dto.commandsToDelete = [];
          if (mapping.id && mapping.id != null)
            this.dto.commandsToDelete.push(mapping.id);
          return;
        }
      }
    },

    // <-

    //
    //
    // COMMAND PROPERTY ->

    onCommandPropertyRowSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedCommandProperty = items[0];
      } else {
        this.selectedCommandProperty = null;
      }
    },

    commandPropertyAddClicked: function () {
      if (this.command.commandProperties === null)
        this.command.commandProperties = [];

      this.command.commandProperties.push(this.makeEmptyCommandProperty());
    },

    commandPropertyDeleteClicked: function () {
      for (let i = 0; i < this.command.commandProperties.length; i++) {
        if (
          this.command.commandProperties[i] === this.selectedCommandProperty
        ) {
          if (this.selectedCommandProperty.id !== null) {
            this.command.commandPropertiesToDelete.push(
              this.selectedCommandProperty.id
            );
          }

          this.command.commandProperties.splice(i, 1);
          this.selectedCommandProperty = null;
          return;
        }
      }
    },

    // <-

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    makeEmptyParameter: function () {
      return {
        deviceId: null,
        parameterId: null,
        parameterName: null,
        code: null,
        translateExpression: null,
        minValue: null,
        maxValue: null,
      };
    },

    makeEmptyCommand: function () {
      return {
        name: null,
        description: null,
        value: null,
        deviceTypeId: null,
        commandProperties: [],
        commandPropertiesToDelete: [],
      };
    },

    makeEmptyCommandProperty: function () {
      return {
        commandId: null,
        code: null,
        name: null,
        description: null,
        defaultValue: null,
      };
    },

    copyCommand: function (command) {
      let obj = {};

      for (const field in command) {
        obj[field] = command[field];
      }

      if (command.commandProperties !== null) {
        obj.commandProperties = [];
        for (let i = 0; i < command.commandProperties.length; i++)
          obj.commandProperties.push(
            this.copyCommandProperty(command.commandProperties[i])
          );
      } else {
        obj.commandProperties = [];
      }

      if (command.commandPropertiesToDelete != null) {
        obj.commandPropertiesToDelete =
          command.commandPropertiesToDelete.slice();
      } else {
        obj.commandPropertiesToDelete = [];
      }

      return obj;
    },

    copyCommandProperty: function (commandProperty) {
      let obj = {};

      for (const field in commandProperty) {
        obj[field] = commandProperty[field];
      }

      return obj;
    },

    copyParameter: function (parameter) {
      let obj = {};

      for (const field in parameter) {
        obj[field] = parameter[field];
      }

      return obj;
    },
  },
};
</script>
<style>
.hidden {
  display: none;
}
.form-group {
  margin-bottom: 1rem;
}
</style>
